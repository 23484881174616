/* You can add global styles to this file, and also import other style files */

@import "~font-awesome/css/font-awesome.css";
@import "~ngx-toastr/toastr.css";

@font-face {
    font-family: "DS Marker Felt";
    src: url("/assets/fonts/DS-Marker-Felt.ttf.woff") format("woff"),
        url("/assets/fonts/DS-Marker-Felt.ttf.svg#DS-Marker-Felt") format("svg"),
        url("/assets/fonts/DS-Marker-Felt.ttf.eot"),
        url("/assets/fonts/DS-Marker-Felt.ttf.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #fdf6de
}

@media (max-width: 576px) {
    .hidden-xs {
        display: none !important;
    }

    .visible-xs {
        display: block !important;
    }

    .content-body {
        padding: 50px 0px !important
    }
}

.content-body {
    background-color: #fdf6de;
    /* padding:20px 20px !important */
}

.toast-success {
    text-align: bottom !important;
}

.toast-error {
    text-align: bottom !important;
}

.toast-info {
    text-align: bottom !important;
}

.toast-warning {
    text-align: bottom !important;
}

.fa {
    color: #01b1af;
    margin-right: 10%;
    margin-bottom: 5%;
    margin-top: 5%;
}

* {
    font-family: "Muli", sans-serif;
}

html,
body {
    font-family: "Muli", sans-serif;
    width: 100%;
    overflow-x: hidden;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
a {
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content-page {
    padding: 200px 100px 100px;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    overflow-y: visible;
}

.modal-header {
    text-align: right;
    display: inline-block;
}

.close {
    border-radius: 2px;
    border: none;
    background: none;
}

.sem-negrito {
    font-weight: 300;
    font-size: 18px;
}

.img-button-landing {
    width: 100%;
    border-radius: 200px;
    border: 2px solid #fff;
    -moz-box-shadow: 0px 6px 5px #ccc;
    -webkit-box-shadow: 0px 6px 5px #ccc;
    box-shadow: 0px 6px 5px #ccc;
    -moz-border-radius: 190px;
    -webkit-border-radius: 190px;
}

.button-cadastro {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    border: 5px solid white;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.carousel-control-prev-icon {
    background-color: gray;
    border-radius: 20px;
}

.carousel-control-next-icon {
    background-color: gray;
    border-radius: 20px;
}

.btn-orange {
    background-color: #f48047;
    color: white;
    font-family: DS Marker Felt;
    font-size: 25px;
}

.btn-success2 {
    color: #ebedf2;
    background-color: #01b1af;
    background-image: none;
    border-color: #ebedf2;
    border-radius: 20px;
}

.btn-success2:hover {
    color: #01b1af;
    background-color: #ebedf2;
    border-color: #ebedf2;
}

.pro-qty {
    width: 123px;
    height: 46px;
    border: 2px solid #ebebeb;
    padding: 0 15px;
    float: left;
    margin-right: 14px;
    user-select: none;
}

.pro-qty .qtybtn.dec {
    font-size: 30px;
}

.pro-qty .qtybtn {
    font-size: 24px;
    color: #b2b2b2;
    float: left;
    line-height: 43px;
    cursor: pointer;
    width: 18px;
}

.pro-qty input {
    user-select: none;
    text-align: center;
    width: 52px;
    font-size: 14px;
    font-weight: 700;
    border: none;
    color: #4c4c4c;
    line-height: 43px;
    float: left;
}

/* #remarketing-body{
    background-color:white;
    width: 100%;
    font-family: "Muli", sans-serif;

} */

a {
    color: #01b1af;
    text-decoration: none;
    background-color: transparent;
}


.footer-widget {
    line-height: 36px;
    font-size: 16px;
    color: #6d6d6d;
}

.footer-widget a {
    font-size: 18px;
    color: #252525;
    margin-bottom: 0;
}

.footer {
    background: #efefef;
    padding-top: 30px;
}

.footer-box {
    margin-bottom: 2%;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #6d6d6d;
}


@media(max-width:992px) {
    .navbar-collapse {
        height: 110vh;
    }
}

.newslatter-item .subscribe-form button {
    display: inline-block;
    color: #ffffff;
    background: #ea85de;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #ea85de;
    position: relative;
    right: 0;
    top: 0;
    padding: 13px 20px 12px;
    cursor: pointer;
    border-radius: 5px;
}

.newslatter-item .subscribe-form input {
    color: #b2b2b2;
    background: #dadada;
    width: 100%;
    height: 46px;
    font-size: 16px;
    border: none;
    padding-left: 20px;
    border-radius: 5px;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

.copyright-reserved {
    padding: 15px;
}

.footer-widget h5 {
    color: #ea85de;
    font-weight: 700;
    margin-bottom: 26px;
}

.modal-body {
    padding: 0;
}

.modal-open {
    padding: 0 !important;
}

.pagination .page-item.active .pagination.page-link {
    z-index: 1;
    color: #fff;
    background-color: #01b1af;
    border-color: #01b1af;
}

.pagination .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #01b1af;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pagination .page-link:hover {
    color: #000;
}

.pagination .page-link:hover,
.pagination .page-link.active {
    background-color: #01b1af;
    color: #fff;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #01b1af;
    border-color: #01b1af;
}

.btn-outline-secondary {
    color: #ebedf2;
    background-color: #01b1af;
    background-image: none;
    border-color: #ebedf2;
}

.section-title h2 {
    color: #01b1af;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 38px;
    position: relative;
}

.section-title h2:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    width: 80px;
    height: 3px;
    background: #f8b3cf;
    content: "";
    margin: 0 auto;
}


.m-loader {
    position: relative;
}

.m-loader:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: m-loader-rotate 0.6s linear infinite;
    -moz-animation: m-loader-rotate 0.6s linear infinite;
    -ms-animation: m-loader-rotate 0.6s linear infinite;
    -o-animation: m-loader-rotate 0.6s linear infinite;
    animation: m-loader-rotate 0.6s linear infinite;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .m-loader:before {
        animation: none !important;
    }
}

.m-loader:before {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.7rem;
    margin-left: -0.7rem;
    border-top-width: 2px;
    border-right-width: 2px;
}

.m-loader.m-loader--lg:before {
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    margin-left: -1rem;
    border-top-width: 3px;
    border-right-width: 3px;
}

.m-loader.m-loader--sm:before {
    width: 1rem;
    height: 1rem;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    border-top-width: 1px;
    border-right-width: 1px;
}

.m-loader.m-loader--right:before {
    left: auto;
    right: 0.7rem;
}

.m-loader.m-loader--right.m-loader--lg:before {
    right: 1rem;
}

.m-loader.m-loader--right.m-loader--sm:before {
    right: 0.5rem;
}

.m-loader.m-loader--right.btn {
    padding-right: 3.1rem;
}

.m-loader.m-loader--right.btn.m-loader--lg {
    padding-right: 4.5rem;
}

.m-loader.m-loader--right.btn.m-loader--sm {
    padding-right: 2.25rem;
}

.m-loader.m-loader--left:before {
    left: 1.4rem;
}

.m-loader.m-loader--left.m-loader--lg:before {
    left: 2rem;
}

.m-loader.m-loader--left.m-loader--sm:before {
    left: 1rem;
}

.m-loader.m-loader--left.btn {
    padding-left: 3.1rem;
}

.m-loader.m-loader--left.btn.m-loader--lg {
    padding-left: 4.5rem;
}

.m-loader.m-loader--left.btn.m-loader--sm {
    padding-left: 2.25rem;
}

@-webkit-keyframes m-loader-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes m-loader-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes m-loader-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes m-loader-rotate {
    to {
        transform: rotate(360deg);
    }
}

.m-loader:before {
    border-top-color: #dfe2ea;
}

.m-loader.m-loader--skin-dark:before {
    border-top-color: #acafba;
}

.m-loader.m-loader--brand:before {
    border-top-color: #01b1af;
}

.m-loader.m-loader--metal:before {
    border-top-color: #c4c5d6;
}

.m-loader.m-loader--light:before {
    border-top-color: #ffffff;
}

.m-loader.m-loader--accent:before {
    border-top-color: #00c5dc;
}

.m-loader.m-loader--focus:before {
    border-top-color: #01b1af;
}

.m-loader.m-loader--primary:before {
    border-top-color: #01b1af;
}

.m-loader.m-loader--success:before {
    border-top-color: #01b1af;
}

.m-loader.m-loader--info:before {
    border-top-color: #36a3f7;
}

.m-loader.m-loader--warning:before {
    border-top-color: #ffb822;
}

.m-loader.m-loader--danger:before {
    border-top-color: #f4516c;
}


.nav.nav-pills,
.nav.nav-tabs {
    margin-bottom: 20px;
}

.nav.nav-pills .nav-item,
.nav.nav-tabs .nav-item {
    margin-left: 5px;
}

.nav.nav-pills .nav-item:first-child,
.nav.nav-tabs .nav-item:first-child {
    margin-left: 0;
}

.nav.nav-pills .nav-link,
.nav.nav-tabs .nav-link {
    font-weight: 400;
}

.nav.nav-pills .nav-link [class^="la-"],
.nav.nav-pills .nav-link [class*=" la-"],
.nav.nav-tabs .nav-link [class^="la-"],
.nav.nav-tabs .nav-link [class*=" la-"] {
    font-size: 1.4rem;
}

.nav.nav-pills .nav-link [class^="fa-"],
.nav.nav-pills .nav-link [class*=" fa-"],
.nav.nav-tabs .nav-link [class^="fa-"],
.nav.nav-tabs .nav-link [class*=" fa-"] {
    font-size: 1.2rem;
}

.nav.nav-pills .nav-link [class^="flaticon-"],
.nav.nav-pills .nav-link [class*=" flaticon-"],
.nav.nav-tabs .nav-link [class^="flaticon-"],
.nav.nav-tabs .nav-link [class*=" flaticon-"] {
    font-size: 1.6rem;
}

.nav.nav-pills .nav-link i,
.nav.nav-tabs .nav-link i {
    vertical-align: middle;
    line-height: 0;
    display: inline-block;
    margin-right: 0.5rem;
}

.nav.nav-pills.m-nav-pills--btn-pill .m-tabs__link {
    border-radius: 20px;
}

.nav.nav-pills.m-nav-pills--btn-sm .m-tabs__link {
    padding: 0.6em 1.4em;
    font-size: 0.85rem;
}

.nav.nav-pills .nav-link,
.nav.nav-tabs .nav-link {
    color: #6f727d;
}

.nav.nav-pills .nav-link.m-nav-link--icon i,
.nav.nav-tabs .nav-link.m-nav-link--icon i {
    color: #6f727d;
}

.nav.nav-pills .nav-link.disabled,
.nav.nav-tabs .nav-link.disabled {
    color: #afb2c1;
}

.nav.nav-pills .nav-link.disabled i,
.nav.nav-tabs .nav-link.disabled i {
    color: #ccced7;
}

.nav.nav-pills .nav-link.active {
    color: #ffffff;
}

.nav.nav-pills .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--brand .nav-link.active {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--brand .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--brand .nav-item.show .nav-link {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--brand .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--metal .nav-link.active {
    background: #c4c5d6;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--metal .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--metal .nav-item.show .nav-link {
    background: #c4c5d6;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--metal .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--light .nav-link.active {
    background: #ffffff;
    color: #282a3c;
}

.nav.nav-pills.nav-pills--light .nav-link.active i {
    color: #1c1d2a;
}

.nav.nav-pills.nav-pills--light .nav-item.show .nav-link {
    background: #ffffff;
    color: #282a3c;
}

.nav.nav-pills.nav-pills--light .nav-item.show .nav-link i {
    color: #1c1d2a;
}

.nav.nav-pills.nav-pills--accent .nav-link.active {
    background: #00c5dc;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--accent .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--accent .nav-item.show .nav-link {
    background: #00c5dc;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--accent .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--focus .nav-link.active {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--focus .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--focus .nav-item.show .nav-link {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--focus .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--primary .nav-link.active {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--primary .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--primary .nav-item.show .nav-link {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--primary .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--success .nav-link.active {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--success .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--success .nav-item.show .nav-link {
    background: #01b1af;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--success .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--info .nav-link.active {
    background: #36a3f7;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--info .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--info .nav-item.show .nav-link {
    background: #36a3f7;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--info .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--warning .nav-link.active {
    background: #ffb822;
    color: #111111;
}

.nav.nav-pills.nav-pills--warning .nav-link.active i {
    color: #020202;
}

.nav.nav-pills.nav-pills--warning .nav-item.show .nav-link {
    background: #ffb822;
    color: #111111;
}

.nav.nav-pills.nav-pills--warning .nav-item.show .nav-link i {
    color: #020202;
}

.nav.nav-pills.nav-pills--danger .nav-link.active {
    background: #f4516c;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--danger .nav-link.active i {
    color: #f0f0f0;
}

.nav.nav-pills.nav-pills--danger .nav-item.show .nav-link {
    background: #f4516c;
    color: #ffffff;
}

.nav.nav-pills.nav-pills--danger .nav-item.show .nav-link i {
    color: #f0f0f0;
}

.visible-xs {
    display: none !important;
}

@media (max-width: 820px) {
    .hidden-xs {
        display: none !important;
    }

    .visible-xs {
        display: block !important;
    }
}

.carousel {
    position: relative;
    z-index: 0 !important;
}


.produtcs-resgate h5 {
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.produtcs-resgate .extra-info {
    display: none;
}

.produtcs-resgate .btn {
    color: #fff !important;
}

.produtcs-resgate .btn-danger {
    padding: 0.2rem 0.5rem;
}

.produtcs-resgate .btn-primary {
    padding: 0.2rem 0.5rem;
}

.produtcs-resgate .btn-primary:hover {
    background-color: #02918e;
    border-color: #02918e;
}

.btn-primary2 {
    padding: 10px ;
    vertical-align: middle ;
    color: #01b1af ;
    background-color: white ;
    border-radius: 25px ;
    border-color: #02918e;
}

.btn-primary2:hover {
    background-color: #02918e;
    background: #02918e ;
    color: #fff ;
    border-color: #02918e;
}

/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
.label-light-primary {
    color: #3699ff;
    background-color: #e1f0ff;
    padding: .15rem .75rem;
    border-radius: .42rem;
}

.label-light-warning {
    color: #ffe1a8;
    background-color: #ffab03;
    padding: .15rem .75rem;
    border-radius: .42rem;
}

.label-light-success {
    color: #1bc5bd;
    background-color: #c9f7f5;
    padding: .15rem .75rem;
    border-radius: .42rem;
}

.label-light-danger {
    color: #f64e60;
    background-color: #ffe2e5;
    padding: .15rem .75rem;
    border-radius: .42rem;
}